<template>
	<div>
		<layout-default>
			<div>
				<b-table
					:data="listArr"
					:columns="columns"
					:bordered="tableOptions.isBordered"
					:striped="tableOptions.isStriped"
					:narrowed="tableOptions.isNarrowed"
					:hoverable="tableOptions.isHoverable"
					:loading="tableOptions.isLoading"
					:focusable="tableOptions.isFocusable"
					:mobile-cards="tableOptions.hasMobileCards"
				></b-table>
				<div
					class="text-bold"
					style="text-align: right; padding-top: 20px; padding-right: 10px"
				>
					{{ totalStockValue.toFixed(2) }}
				</div>
				<download-csv :data="listArr">
					<div class="margin-bottom-30 margin-top-30">
						<b-button class="is-success">
							Download the detailed inventory report
						</b-button>
					</div>
				</download-csv>
			</div>
		</layout-default>
	</div>
</template>

<script>
import GetStockInventoryValuesQuery from '@/_srcv2/graphql/stock/queries/getStockInventoryValues.query.gql'
import { useQuery, useResult } from '@vue/apollo-composable'
import Store from '@/store'
import { onMounted, reactive, ref } from '@vue/composition-api'

export default {
	name: 'InventoryReportDetailed',
	setup() {
		const tableKey = ref(1)
		console.log('tableKey', tableKey.value)
		const tableOptions = reactive({
			isBordered: true,
			isStriped: true,
			isNarrowed: true,
			isHoverable: true,
			isLoading: false,
			isFocusable: true,
			hasMobileCards: true,
		})
		// Vue Apollo Query
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { result, refetch } = useQuery(
			GetStockInventoryValuesQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		const stockInventoryValues = useResult(result, null, (data) => data.stock)
		// * ---------------------------------------------------------------------
		const columns = [
			{
				field: 'stock_id',
				label: 'Stock Num.',
				width: '150',
				centered: true,
			},
			{
				field: 'stock_name',
				label: 'Namn',
				width: '245',
			},
			{
				field: 'outstanding_balance',
				label: 'Utstående Balans',
				width: '40',
				centered: true,
			},
			{
				field: 'outstanding_cost',
				label: 'Utstående Kostnad',
				width: '40',
				numeric: true,
			},
			{
				field: 'inventory_debit',
				label: 'Debet',
				width: '40',
				centered: true,
			},
			{
				field: 'inventory_credit',
				label: 'Kredit',
				width: '40',
				centered: true,
			},
			{
				field: 'inventory_unit_cost',
				label: 'Inventarielista enhet kostnad',
				numeric: true,
				width: '40',
			},
			{
				field: 'inventory_balance',
				label: 'inventarielista balans',
				width: '40',
				centered: true,
			},
			{
				field: 'inventory_stock_value',
				label: 'Inventarielista aktievärde',
				numeric: true,
				width: '40',
			},
		]
		// ****************************************************************************************
		const listArr = ref([])
		const zero = 0
		const getInventoryUnitCost = (item) => {
			const balance = item.outstanding_balance + item.inventory_debit
			const cost = item.outstanding_cost + item.inventory_cost
			return balance === 0 ? zero.toFixed(2) : (cost / balance / 100).toFixed(2)
		}
		// ! ----------------------------------------------------------------
		const getInventoryStockValue = (item) => {
			const stock =
				item.outstanding_balance + item.inventory_debit - item.inventory_credit
			const balance = item.outstanding_balance + item.inventory_debit
			const cost = item.outstanding_cost + item.inventory_cost
			return balance === 0
				? zero.toFixed(2)
				: (stock * (cost / balance / 100)).toFixed(2)
		}
		const fetchData = (data) => {
			return new Promise((resolve, reject) => {
				console.log('stockInventoryValues.value', stockInventoryValues.value)
				let mappedArray = stockInventoryValues.value.map((item) => {
					return {
						stock_id: item.stock_id,
						stock_name: item.stock_name,
						outstanding_balance: item.outstanding_balance,
						outstanding_cost: (item.outstanding_cost / 100).toFixed(2),
						inventory_debit: item.inventory_debit,
						inventory_credit: item.inventory_credit,
						inventory_balance:
							item.outstanding_balance +
							item.inventory_debit -
							item.inventory_credit,
						inventory_unit_cost: getInventoryUnitCost(item),
						inventory_stock_value: getInventoryStockValue(item),
					}
				})
				if (data !== null || undefined) {
					listArr.value = mappedArray
					resolve(listArr.value)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const totalStockValue = ref(0)
		const stockValuesArr = ref(0)
		onMounted(() => {
			refetch()
				.then(() => fetchData())
				.then(() => objectToCSV(listArr.value))
				.then(() => {
					stockValuesArr.value = listArr.value.map((item) =>
						parseFloat(item.inventory_stock_value),
					)
				})
				.then(() => {
					totalStockValue.value = stockValuesArr.value.reduce(function (
						accumulator,
						item,
					) {
						return accumulator + item
					},
					0)
				})
		})
		const objectToCSV = (data) => {
			const csvRows = []

			// get headers
			const headers = Object.keys(data[0])
			csvRows.push(headers.join(','))

			// loop over values
			for (const row of data) {
				const values = headers.map((header) => {
					const escaped = ('' + row[header]).replace(/"/g, '\\"')
					return `"${escaped}"`
				})
				csvRows.push(values.join(','))
			}

			// join and return all lines
			console.log('csv', csvRows.join('\n'))
			return csvRows.join('\n')
		}
		// ****************************************************************************************
		return {
			listArr,
			columns,
			tableOptions,
			totalStockValue,
		}
	},
}
</script>
<style scoped>
.list-title {
	font-weight: bold;
	color: coral;
	margin-bottom: 20px;
}
</style>
